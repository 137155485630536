import React from 'react';
import T from 'i18n';
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ClearIcon, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import LookupMui from 'features/common/MaterialBasedComponents/Lookup';
import { useDispatch } from 'react-redux';
import { fetchAuthorsLookup } from 'features/viewing/redux/fetchAuthorsLookup';
import { fetchRecipientsLookup } from 'features/viewing/redux/fetchRecipientsLookup';
import { fetchCreatedByLookup } from 'features/viewing/redux/fetchCreatedByLookup';
import debounce from 'utils/debounce';
import { fetchDocumentsDocFinder } from '../redux/fetchDocumentsDocFinder';
import { useSelector } from 'react-redux';
import { selectCurrentFile } from 'common/selectors';
import { makeStyles } from '@mui/styles';
import DateInput from 'features/common/FormControls/DateInput';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSetShowDocFinderFilter } from '../redux/setShowDocFinderFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import TrialBundlesMenu from '../SearchCenter/TrialBundlesMenu';

const useStyles = makeStyles({
  root: {
    margin: '0 0 0 -1rem',
    padding: '1rem 1rem 1rem 0',
    width: '100%',
    '& .MuiGrid-item': {
      paddingTop: '0 !important',
    },

    '& .MuiInput-input ': {
      padding: '4px 4px 4px 0px !important',
    },
  },
});

const TrialBookFilter = React.forwardRef(({ onChange, value, ...rest }: any, ref: any) => {
  const [trialBundlesMenu, setTrialBundlesMenu] = React.useState<null | HTMLElement>(null);

  return (
    <>
      <Typography
        style={{
          marginLeft: 0,
          alignSelf: 'center',
          marginTop: '0.5rem',
          fontWeight: 'bold',
        }}
      >
        {T.translate('filters.trialBundles')}
      </Typography>
      <TextField
        variant="standard"
        sx={{
          '& .MuiInput-root:hover .MuiIconButton-root': {
            visibility: (value || []).length > 0 ? 'visible !important' : 'hidden',
          },
        }}
        style={{ width: '100%' }}
        placeholder="Select trial books"
        value={
          (value || []).length > 0
            ? value[0].name + (value.length > 1 ? ` & ${value.length - 1} more ...` : '')
            : ''
        }
        InputProps={{
          readOnly: true,
          endAdornment: (
            <IconButton
              size="small"
              style={{ padding: '0.3rem', visibility: 'hidden' }}
              onClick={(e: any) => {
                onChange([]);
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <ClearIcon style={{ fontSize: '1.2rem' }} />
            </IconButton>
          ),
        }}
        onClick={e => setTrialBundlesMenu(e.currentTarget)}
      />
      {trialBundlesMenu && (
        <TrialBundlesMenu
          value={value || []}
          onChange={onChange}
          anchorEl={trialBundlesMenu}
          onClose={() => setTrialBundlesMenu(null)}
          isOpen
          noClear
        />
      )}
    </>
  );
});

const LookupWithLabel = React.forwardRef(({ label, fetch, ...rest }: any, ref: any) => {
  return (
    <>
      <Typography
        style={{
          marginLeft: 0,
          alignSelf: 'center',
          marginTop: '0.5rem',
          fontWeight: 'bold',
        }}
      >
        {label}
      </Typography>
      <LookupMui {...rest} fetchFunction={fetch} minInputLength={2} variant="standard" multiple />
    </>
  );
});

const SelectWithLabel = React.forwardRef(({ label, items, ...rest }: any, ref: any) => {
  return (
    <>
      <Typography
        style={{
          marginLeft: 0,
          alignSelf: 'center',
          marginTop: '0.5rem',
          fontWeight: 'bold',
        }}
      >
        {label}
      </Typography>
      <Select {...rest} variant="standard" sx={{ width: '100%' }}>
        {items.map((item: any) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
});

export default () => {
  const {
    // watch,
    handleSubmit,
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      search: '',
      dateFrom: '',
      dateTo: '',
      author: undefined,
      recipient: undefined,
      trialBook: undefined,
      createdBy: undefined,
      hasAnnotations: 'null',
      privateOnly: 'null',
      hasHyperlinks: 'null',
    },
  });

  const dispatch = useDispatch();
  const classes = useStyles();

  const onSubmit = debounce((data: any) => dispatch(fetchDocumentsDocFinder(data)), 250);

  // make search react on change of form values
  // useEffect(() => {
  //   const subscription = watch(() => handleSubmit(onSubmit)());
  //   return () => subscription.unsubscribe();
  // }, [handleSubmit, onSubmit, watch]);

  const hasFileOpenned = !!useSelector(selectCurrentFile);
  const { showDocFinderFilter, setShowDocFinderFilter } = useSetShowDocFinderFilter();

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        transition: '0.3s all',
        ...(!hasFileOpenned && {
          marginRight: showDocFinderFilter ? 0 : '-100%',
        }),
        ...(hasFileOpenned && {
          position: 'absolute',
          right: showDocFinderFilter ? 0 : '-100%',
          border: '1px solid #e0e0e0',
          backgroundColor: 'white',
          height: 'calc(100% - 84px)',
          paddingLeft: '1rem',
          paddingRight: '-0.5rem',
          zIndex: 3,
        }),
      }}
    >
      {hasFileOpenned && (
        <Tooltip title={T.translate('filters.hideFilter')}>
          <div //needs to have div to be able to show tooltip on hover
            style={{
              position: 'absolute',
              top: '1rem',
              right: '1rem',
              cursor: 'pointer',
              fontSize: '1.2rem',
              zIndex: 4,
            }}
          >
            <FontAwesomeIcon onClick={() => setShowDocFinderFilter(false)} icon={faTimesCircle} />
          </div>
        </Tooltip>
      )}
      <PerfectScrollbar style={{ height: '100%', width: '20rem' }}>
        <Grid className={classes.root} container spacing={2}>
          <Grid item xs={12}>
            <Typography
              style={{
                marginLeft: 0,
                alignSelf: 'center',
                marginTop: '0.5rem',
                fontWeight: 'bold',
              }}
            >
              {T.translate('case.search')}
            </Typography>
            <Controller
              name="search"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  sx={{
                    '& .Mui-focused .MuiIconButton-root': {
                      visibility: field.value ? 'visible !important' : 'hidden',
                    },
                  }}
                  style={{ width: '100%' }}
                  placeholder="Look up by file name, Doc ID, Global Page or other meta data"
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      handleSubmit(onSubmit)();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        id="clear-search"
                        size="small"
                        style={{ padding: '0.3rem', visibility: 'hidden' }}
                        onMouseDown={() => {
                          setValue('search', '');
                        }}
                      >
                        <ClearIcon style={{ fontSize: '1.2rem' }} />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Typography
                style={{
                  marginLeft: 0,
                  alignSelf: 'center',
                  marginTop: '0.5rem',
                  fontWeight: 'bold',
                }}
              >
                {T.translate('filters.dateFromTo')}
              </Typography>
              <div style={{ display: 'flex' }}>
                <Controller
                  name="dateFrom"
                  control={control}
                  render={({ field }) => (
                    <DateInput
                      {...field}
                      mui
                      placeholder={T.translate('generic.from')}
                      style={{ marginTop: '0' }}
                      variant="standard"
                      sx={{
                        '& .Mui-focused .MuiIconButton-root': {
                          visibility: field.value ? 'visible !important' : 'hidden',
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            size="small"
                            style={{ padding: '0.3rem', visibility: 'hidden' }}
                            onMouseDown={() => setValue('dateFrom', '')}
                          >
                            <ClearIcon style={{ fontSize: '1.2rem' }} />
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                />
                <Controller
                  name="dateTo"
                  control={control}
                  render={({ field }) => (
                    <DateInput
                      {...field}
                      mui
                      placeholder={T.translate('generic.to')}
                      style={{ marginLeft: '1rem', marginTop: '0' }}
                      variant="standard"
                      sx={{
                        '& .Mui-focused .MuiIconButton-root': {
                          visibility: field.value ? 'visible !important' : 'hidden',
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            size="small"
                            style={{ padding: '0.3rem', visibility: 'hidden' }}
                            onMouseDown={() => setValue('dateTo', '')}
                          >
                            <ClearIcon style={{ fontSize: '1.2rem' }} />
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="author"
              control={control}
              render={({ field }) => {
                return (
                  <LookupWithLabel
                    {...field}
                    fetch={fetchAuthorsLookup}
                    label={T.translate('filters.authors')}
                    placeholder="Search by author's name"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="recipient"
              control={control}
              render={({ field }) => {
                return (
                  <LookupWithLabel
                    {...field}
                    fetch={fetchRecipientsLookup}
                    label={T.translate('filters.recipients')}
                    placeholder="Search by recipient's name"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="trialBook"
              control={control}
              render={({ field }) => {
                return <TrialBookFilter {...field} />;
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="createdBy"
              control={control}
              render={({ field }) => {
                return (
                  <LookupWithLabel
                    {...field}
                    fetch={fetchCreatedByLookup}
                    label={T.translate('filters.uploadedBy')}
                    placeholder="Search by uploaded by's name"
                  />
                );
              }}
            />
          </Grid>
          {/* <Divider style={{ width: '100%' }} /> */}
          <Grid item xs={12}>
            <Controller
              name="hasAnnotations"
              control={control}
              render={({ field }) => {
                return (
                  <SelectWithLabel
                    {...field}
                    variant="standard"
                    label={T.translate('filters.hasAnnotations')}
                    items={[
                      { id: 'false', name: T.translate('filters.withoutAnnotations') },
                      { id: 'null', name: T.translate('filters.allDocuments') },
                      { id: 'true', name: T.translate('filters.withAnnotations') },
                    ]}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="hasHyperlinks"
              control={control}
              render={({ field }) => {
                return (
                  <SelectWithLabel
                    {...field}
                    variant="standard"
                    label={T.translate('filters.privatePublicHyperlinks')}
                    items={[
                      { id: 'false', name: T.translate('filters.withoutPublicHyperlinks') },
                      { id: 'null', name: T.translate('filters.allDocuments') },
                      { id: 'true', name: T.translate('filters.withPublicHyperlinks') },
                    ]}
                  />
                );
              }}
            />
          </Grid>{' '}
          <Grid item xs={12}>
            <Controller
              name="privateOnly"
              control={control}
              render={({ field }) => {
                return (
                  <SelectWithLabel
                    {...field}
                    variant="standard"
                    label={T.translate('filters.privatePublicAnnotations')}
                    items={[
                      { id: 'false', name: T.translate('filters.publicOnly') },
                      { id: 'null', name: T.translate('filters.allDocuments') },
                      { id: 'true', name: T.translate('filters.privateOnly') },
                    ]}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </PerfectScrollbar>
      <div style={{ display: 'flex', width: '100%' }}>
        <Button
          type="reset"
          fullWidth
          variant="contained"
          color="inherit"
          sx={{ mt: 3, mb: 3, fontSize: '1rem' }}
          onClick={() => reset()}
        >
          {T.translate('generic.clear')}
        </Button>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ m: 3, ml: 2, fontSize: '1rem' }}
          onClick={handleSubmit(onSubmit)}
        >
          {T.translate('generic.search')}
        </Button>
      </div>
    </div>
  );
};
